






import { Component, Vue } from 'vue-property-decorator';
import { Role } from '@/services/apis/user.d';
import AccountModule from '@/store/modules/account';

@Component
export default class extends Vue {
  created(): void {
    const { role, redirect } = this.$route.query;
    if (role) {
      const newRole = parseInt(role as unknown as string, 10) as Role;
      AccountModule.SET_ROLE(newRole);
      if (newRole === 1) {
        if (redirect) {
          window.sessionStorage.setItem('ACCOUNT_LOGIN_REDIRECT_URL', decodeURIComponent(redirect as string));
        } else {
          window.sessionStorage.setItem('ACCOUNT_LOGIN_REDIRECT_URL', '//supplier.kd-yun.com/index');
        }
      }
    } else {
      AccountModule.SET_ROLE();
    }
  }
}
